@import 'src/theme/constants';

.rbl-2024-certificate-wrapper {

  .hide-small {
    @media (max-width: 992px) {
      display: none !important;
    }
  }

  .hide-big {
    @media (min-width: 993px) {
      display: none !important;
    }
  }

  --ion-color-secondary: #001f47;
  --ion-color-secondary-rgb: 0, 31, 71;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #00152f;
  --ion-color-secondary-tint: #002a5b;

  color: white;
  position: relative;
  overflow: hidden;
  padding: 0;
  background: white;
  &#certificate-page-1 {
    @media(min-width: 993px) {
      background: url("../../../assets/rbl/bg_front.svg") repeat center center;
      background-size: cover;
    }
  }
  &#certificate-page-2 {
    @media(min-width: 993px) {
      background: url("../../../assets/rbl/bg_back_grad.svg") repeat center center;
      background-size: cover;
    }
  }
  @media(min-width: 993px) {
    box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.5);
    height: 640px;
  }
  margin-bottom: $jmt-spacing * 2;

  @media (max-width: 992px) {
    width: 360px;
    margin: 0 auto;
  }

  .certificate {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media (min-width: 993px) {
      padding: 0;
      height: 100%;
      color: white;
    }

    &__upper {
      margin-top: 32px;
      padding: $jmt-spacing;
      @media (min-width: 993px) {
        display: flex;
        height: calc(100% - 90px);
        padding: 0;
        margin: 0;
      }

      &__logo {
        display: flex;
        justify-content: space-between;

        &__image {
          min-width: 42px;
        }
      }
    }

    &__footer {
      @media(min-width: 993px) {
        height: 90px;
        background: transparent;
        display: flex;
        justify-content: center;
      }

      &__logo {
        padding-top: 64px;
        width: 76px;
        height: 68px;
        flex-shrink: 0;
        margin-right: 24px;
        @media(min-width: 993px) {
          padding-left: 12px;
          padding-top: 12px;
        }
      }

      &__text {
        @media(max-width: 992px) {
          padding: 0 $jmt-spacing;
        }

        font-size: 12px;
        text-align: justify;
        line-height: 1.3;
        flex-grow: 1;

        @media(min-width: 993px) {
          p {
            width: 380px;
            margin: 0;
            text-align: left;
          }
        }
      }

      &__qr {
        @media(max-width: 992px) {
          display: none;
        }
        padding-right: 32px;
        padding-left: 32px;

        > canvas {
          max-width: 76px;
          max-height: 76px;
        }
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }

    .bolder {
      font-weight: 900;
    }

    &__talent-card-wrapper {
      @media (min-width: 993px) {
        max-width: 33%;
        padding: 24px 16px 16px 16px;
        .talentcard-medium {
          scale: .9;
        }
      }
      @media (max-width: 992px) {
        display: flex;
        justify-content: center;
        padding-top: 32px;
      }
    }

    h1 {
      font-family: RBLDisplayHeavyItalic, Arial, sans-serif;
      font-weight: 800;
      font-size: 72px;
      font-style: italic;
      line-height: 68px;
      color: black;
      max-width: 480px;
      margin-top: 20px;
      letter-spacing: 8px;
      @media (min-width: 993px) {
        color: white;
      }
    }

    &__texts {
      @media (min-width: 993px) {
        padding-left: 60px;
        padding-top: 46px;
        padding-right: 16px;
      }
      position: relative;

      &__saison {
        font-family: BullCondensedMedium, Arial, sans-serif;
        color: white;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }

      h2 {
        font-family: BullCondensed;
        font-size: 32px;
        margin-top: $jmt-spacing*3;
        text-transform: uppercase;
        letter-spacing: 3px;
      }

      p {
        font-family: Bull;
        font-weight: 500;
        font-size: 13px;
        max-width: 420px;
      }

      &__signature {
        margin-top: $jmt-spacing*5;
        font-size: 18px;
        display: flex;
        flex-direction: column;

        &__date {
          align-self: flex-end;
          &__date {
            padding-top: 26px;
            font-size: 11px;
          }
          &__footer {
            font-size: 11px;
            color: rgba(255,255,255,0.8);
            border-top: none;
            @media (max-width: 992px) {
              width: 200px;
            }
          }
        }

        &__signature {
          margin-top: 32px;
          @media (min-width: 993px) {
            margin-right: 64px;
            margin-top: 0;
          }

          &__svg {
            width: 130px;
            height: 60px;
          }

          &__footer {
            font-size: 11px;
            @media (max-width: 992px) {
              width: 280px;
              margin-bottom: 64px;
            }

            &__position {
              color: rgba(255,255,255,0.8);
            }
          }
        }

        @media (min-width: 993px) {
          flex-direction: row;
        }
      }

      &__logo {
        @media (min-width: 993px) {
          margin: -20px 0 0 0;
          position: absolute;
          right: $jmt-spacing*2;
          top: $jmt-spacing*3;
          width: 150px;
          height: auto;
          min-width: 52px;
          margin-top: -20px;
        }
      }
    }

    &.page-2 {
      @media(max-width: 992px) {
        padding: $jmt-spacing;
      }

      h1 {
        font-size: 72px;
        color: var(--ion-color-stop1);
        @media(min-width: 993px) {
          margin-top: 48px;
          padding-left: 32px;
          margin-bottom: 26px;
        }
      }

      h5, p {
        color: #000000;
      }

      .certificate__footer__logo {
        width: 130px;
        height: 60px;
        margin-top: 12px;
      }
    }

    &__values {
      @media(min-width: 993px) {
        padding: 0 32px 0 32px;
        height: 445px;
        display: flex;
        gap: $jmt-spacing;
      }

      h5 {
        font-size: 18px;
        font-weight: 700;
      }

      &__col {
        flex-grow: 1;
        flex-basis: 0;

        &__block {
          padding: $jmt-spacing 0;
          @media(min-width: 993px) {
            padding-bottom: $jmt-spacing;
          }
          p {
            font-size: 11px;
            margin: 0;
            padding: 0;
          }

          .values__col__block__number {
            font-family: RBL Display;
            font-size: 19px;
            font-style: italic;
          }

          .values__col__block__name {
            font-family: BullCondensedBold;
            font-size: 19px;
            text-transform: uppercase;
          }

          .certificate__values__block__description {
            margin-top: 4px;
          }
        }
      }
    }
  }
}
